<template>
  <b-modal
    :id="id"
    size="sm"
    :title="title"
    cancel-variant="outline-primary"
    centered
    hide-footer
    @hidden="resetData"
  >
    <div class="modal-report">
      <validation-observer ref="rule">
        <b-form class="content-form">
          <b-form-group
            label-for="fileName"
          >
            <label for="basicInput">File đính kèm<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: 'File đính kèm là bắt buộc'}"
            >
              <div class="d-flex">
                <b-form-input
                  v-model="dataInput.fileName"
                  :disabled="true"
                  :state="errors.length > 0 ? false:null"
                />
                <input
                  ref="upload-file"
                  type="file"
                  class="d-none"
                  accept="application/msword, application/pdf"
                  :disabled="type === 'edit' ? true : false"
                  @change="importFileExcel"
                />
                <b-button
                  :variant="errors.length > 0 ? 'outline-danger':'outline-primary'"
                  class="btn-button-file ml-1"
                  @click="uploadFile"
                >
                  <feather-icon
                    icon="UploadIcon"
                    :class="errors.length > 0 ? 'text-danger':'text-primary'"
                  />
                </b-button>

              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="InputHelp">Nội dung</label>
            <b-form-textarea
              v-model="dataInput.content"
              class="text-area-scroll"
              :disabled="type === 'edit' ? true : false"
            />
          </b-form-group>
        </b-form>
      </validation-observer>
      <footer
        id="footer-modal-hidden"
      >
        <button
          type="button"
          class="btn btn-outline-primary button-position"
          @click="$emit('hiddeModal')"
        >
          Hủy bỏ
        </button>
        <button
          type="button"
          class="btn btn-primary button-position"
          @click="handleCreate($event)"
        >
          Lưu
        </button>
      </footer>

      <b-overlay
        id="overlay"
        :show="isLoading"
        no-wrap
      >
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BModal,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    BOverlay,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      dataCombobox: [],
      dataInput: {
        name: '',
        content: '',
        isAllBussiness: false,
        listBusinessIds: null,
        fileName: '',
        isSecure: false,
        modelFormData: {
          files: '',
          isSecure: false,
        },
      },

    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = {
          name: this.dataDetail.name,
          content: this.dataDetail.content,
          isAllBussiness: this.dataDetail.isAllBussiness,
          listBusinessIds: this.dataDetail.listBusinessIds,
          fileName: this.dataDetail.fileName,
        }
      }
    },
  },
  methods: {
    handleCreate(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        name: '',
        content: '',
        isAllBussiness: false,
        listBusinessIds: [],
        fileName: '',
        isSecure: false,
      }
    },
    checkedBussiness() {
      this.dataInput.listBusinessIds = null
    },
    uploadFile() {
      this.$refs['upload-file'].click()
    },
    importFileExcel(e) {
      this.dataInput.fileName = this.$refs['upload-file'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.dataInput.modelFormData = {
        files: file,
        isSecure: false,
      }
    },
  },

}
</script>

<style lang="scss">
.modal-report {
  .action {
    display: flex;
    justify-content: end;
  }
  .vs__selected-options {
    overflow-y: overlay;
    max-height: 100px;
  }
  #footer-modal-hidden {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 1.4rem 0;
  }
  .button-position {
    margin-left: 0.75rem;
    margin: 0.25rem;
  }
  .text-area-scroll {
    overflow-y: overlay;
    max-height: 300px;
  }
}
</style>
